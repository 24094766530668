
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './ArticleGambit.module.scss';
const propTypes = {
    intro: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'dark', 'outcome', '']),
    color: PropTypes.oneOf(['default', 'yellow', 'red', 'blue', 'green']),
    left: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'small'])
};
const ArticleGambit = ({ intro = '', theme = 'light', color = 'default', left = false, size = 'default', }) => {
    const [firstWord, ...words] = intro.split(' ');
    return (<section data-article-gambit className={cx(s.gambit, s[`${theme}Theme`], s[size], { [s.left]: left })} data-testid="article-gambit-text">
      <span aria-labelledby="word--first">
        <span aria-hidden="true">
          <span className={['drop-cap', s.dropcap, s[color]].join(' ')}>
            {firstWord.substring(0, 1)}
          </span>
          {firstWord.substring(1)}
        </span>
        <span id="word--first" className={s.hidden}>
          {firstWord}
        </span>
      </span>
      {` ${words.join(' ')}`}
    </section>);
};
ArticleGambit.propTypes = propTypes;
export default ArticleGambit;

    async function __Next_Translate__getStaticProps__191c29e404c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Article/components/ArticleGambit/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c29e404c__ as getStaticProps }
  