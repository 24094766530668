
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AnchorLink from '../../../../components/AnchorLink';
import s from './ArticleHeroImageSource.module.scss';
import { trackEvent } from '../../../../utils/analytics';
import { HOME_URL } from '../../../../constants/routes';
const propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    theme: PropTypes.string,
    pageName: PropTypes.string
};
const ArticleHeroImageSource = ({ description, title, url = HOME_URL, theme = 'news', pageName = '', }) => (<div className={cx(s.heroImageSource, s[theme])}>
    <div className={s.sourceDetails}>
      <div>{description}</div>
      <AnchorLink href={url} title={title} onClick={() => {
        trackEvent('Link Clicked', {
            link_name: title,
            link_url: url,
            location: pageName
        });
    }} target="_self" rel="noopener noreferrer">
        {title}
      </AnchorLink>
    </div>
  </div>);
ArticleHeroImageSource.propTypes = propTypes;
export default ArticleHeroImageSource;

    async function __Next_Translate__getStaticProps__19229adfebb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Article/components/ArticleHeroImageSource/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19229adfebb__ as getStaticProps }
  